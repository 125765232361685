import * as Mantine from '@mantine/core';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { PatchnoteSchema } from '../../../utils/schemas';
import { useFormState } from '../../../utils/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import Toastify from 'toastify-js';
import { useParams } from 'react-router-dom';
import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import Winylo, { WINYLO_THEME } from '../../../react_components';
import { MantineProvider } from '@mantine/core';
import { getAttributes, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Link from '@tiptap/extension-link';

import style from './PatchnoteForm.module.css';

export default function PatchnoteForm() {
  const { id } = useParams<{ id: string | undefined }>();
  const queryClient = useQueryClient();

  const [editorContent, setEditorContent] = useState<string>('');

  // Récupération des données de la company si édition
  const { data: patchnote, isLoading: isLoadingCompany } = useQuery(
    ['patchnote', parseInt(id === undefined ? '-1' : id)],
    () => api.patchNotes.getPatchnote(parseInt(id === undefined ? '-1' : id)),
    {
      enabled: id !== undefined,
      onSuccess: (data) => {
        setFormField({
          title: data.title,
          content: data.content,
          type: data.type,
          application: data.application?.id ?? -1,
          scheduledAt: new Date(data.scheduledAt),
        });
      },
    }
  );
  const { data: applications, isLoading: isLoadingApps } = useQuery('applications', api.applications.getAllApplications, {});

  const [formField, handleForm, setFormField] = useFormState<{
    title: string;
    content: string;
    type: string;
    application: number;
    scheduledAt: Date;
  }>({
    title: '',
    content: '',
    type: '',
    application: -1,
    scheduledAt: new Date(),
  });

  useEffect(() => {
    setEditorContent('');
    setFormField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { mutate: createPatchnote } = useMutation(api.patchNotes.createPatchnote, {
    onSuccess: (data, variable) => {
      setEditorContent('');
      setFormField();
      if (editor?.commands) editor.commands.setContent('');
      Toastify({
        text: 'Patchnote créée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleAddCompany() {
    createPatchnote({ ...formField, scheduledAt: toLocaleString(formField.scheduledAt) });
  }

  const { mutate: updatePatchnote } = useMutation(api.patchNotes.updatePatchnote, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('companies');
      Toastify({
        text: 'Patchnote créée/modifiéée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleUpdate() {
    updatePatchnote({
      id: parseInt(id === undefined ? '-1' : id),
      body: {
        title: formField.title,
        content: formField.content,
        type: formField.type,
        application: formField.application,
        scheduledAt: toLocaleString(formField.scheduledAt),
      },
    });
  }

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Superscript, SubScript, Highlight, TextAlign.configure({ types: ['heading', 'paragraph'] })],
    content: formField.content,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  });

  function toLocaleString(date: Date) {
    const formattedDate = date.toLocaleString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const [day, month, year] = formattedDate.split(' ')[0].split('/');
    const time = formattedDate.split(' ')[1];
    return `${year}-${month}-${day} ${time}`;
  }

  useEffect(() => {
    setFormField((o) => {
      return { ...o, content: editorContent };
    });
  }, [editorContent]);

  return (
    <Container>
      <h1 className="center">{id === undefined ? 'Créer' : 'Modifier'} une patchnote</h1>

      {id !== undefined && isLoadingCompany ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Winylo.TextInput
            className={style.formElement}
            label="Titre"
            required
            name="title"
            value={formField.title}
            onChange={handleForm}
            error={!PatchnoteSchema.fields.title.isValidSync(formField.title)}
            style={{ marginTop: '1rem' }}
          />

          <label className={style.label}>
            Contenu<span className={style.error}> *</span>
          </label>

          <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>

          <Winylo.Select
            className={style.formElement}
            label="Type"
            required
            name="type"
            value={formField.type}
            onChange={(v) => setFormField({ ...formField, type: v ?? '' })}
            error={!PatchnoteSchema.fields.type.isValidSync(formField.type)}
            style={{ margin: '1rem 0' }}
            data={['new', 'update', 'corrected']}
          />

          <Winylo.Select
            className={style.formElement}
            label="Application"
            required
            name="application"
            value={formField.application.toString()}
            onChange={(v) => setFormField({ ...formField, application: v ? parseInt(v) : -1 })}
            error={!PatchnoteSchema.fields.application.isValidSync(formField.application)}
            style={{ margin: '1rem 0' }}
            data={[{ value: '-1', label: 'Core' }, ...(applications?.map((app) => ({ value: app.id.toString(), label: app.name })) ?? [])]}
            itemComponent={({ label, value, description, color, selected, ...others }) => (
              <div {...others}>
                <Mantine.Group noWrap>
                  <div>
                    <Mantine.Text
                      weight={500}
                      size="md"
                      style={{
                        color: selected ? WINYLO_THEME.colors.gray[0] : WINYLO_THEME.colors.dark[6],
                      }}
                    >
                      {label}
                    </Mantine.Text>
                  </div>
                </Mantine.Group>
              </div>
            )}
          />

          <Winylo.DatePickerInput
            className={style.formElement}
            label="Date"
            required
            name="scheduledAt"
            value={formField.scheduledAt}
            onChange={(v) => setFormField({ ...formField, scheduledAt: v ? v : new Date() })}
            error={!PatchnoteSchema.fields.scheduledAt.isValidSync(formField.scheduledAt)}
            style={{ margin: '1rem 0' }}
            locale="fr"
          />

          {id === undefined || patchnote === undefined ? (
            <Button disabled={!PatchnoteSchema.isValidSync(formField)} onClick={handleAddCompany}>
              Créer
            </Button>
          ) : (
            <>
              <Button disabled={!PatchnoteSchema.isValidSync(formField)} onClick={handleUpdate}>
                Modifier
              </Button>
            </>
          )}
        </>
      )}
    </Container>
  );
}
