import React, { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastify-js/src/toastify.css';

import Modal from 'react-modal';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { ReactQueryDevtools } from 'react-query/devtools';

import { JWT_PERIOD } from './utils/constantes';
import api from './utils/api';

import { UserContextType, useUser } from './utils/context/User';
import { HasCheckLoginContextType, useHasCheckLogin } from './utils/context/HasCheckLogin';
import { ROLE } from './utils/api/_type';

// Composants
import NeedLogin from './components/NeedLogin';
import SidePannelCompany from './components/SidePannel/SidePannelCompany';
import SidePannelAdmin from './components/SidePannel/SidePannelAdmin';
import SidePannelConsultant from './components/SidePannel/SidePannelConsultant';
import ContactBubble from './components/ContactBubble/ContactBubble';
import IsBrowserOk from './components/IsBrowserOk';

// Pages
// * Autres
import Privacy from './pages/Privacy';
import Winylo from './react_components';

// * Login
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import ForgetPassword from './pages/login/ForgetPassword';
import FormResetPassword from './pages/login/FormResetPassword';
import AppLogin from './pages/login/AppLogin';
import RegisterConfirm from './pages/login/RegisterConfirm';
import ResendEmail from './pages/login/ResendEmail';

// * Utilisateur
import MyProfile from './pages/MyProfile';

// * Applications
import { ApplicationsList as CustomersApplicationsList } from './pages/applications/ApplicationsList';

// * Administration
import ApplicationForm from './pages/admin/Applications/ApplicationForm';
import { ApplicationsList as AdminApplicationsList } from './pages/admin/Applications/ApplicationList';
import AllUsers from './pages/admin/User/AllUsers';
import CreateUser from './pages/admin/User/CreateUser';
import CompanyList from './pages/admin/Companies/CompanyList';
import CompanyFormAdmin from './pages/admin/Companies/CompanyForm';
import Unconfirmed from './pages/admin/Consultant/Unconfirmed';
import Testing from './pages/admin/Companies/Testing';

// * Gestion de l'entreprise
// import MyCompany from './pages/company/MyCompany';
import CompanyForm from './pages/company/CompanyForm';
import UserStats from './pages/company/UserStats/UserStats';
import Storage from './pages/company/Storage/Storage';
import Referal from './pages/company/Referal/Referal';
import TrialRequest from './pages/company/TrialRequest/TrialRequest';
// * Nouvelle gestion utilisateurs
import UserList from './pages/UserManagement/UserList';

// * Consultant
import InformationConsultant from './pages/consultant/Information';
import Commissions from './pages/consultant/Commissions';
import Remunerations from './pages/consultant/Remunerations';

// import Index from './pages/Index';
import { isAdmin, logger } from './utils/utils';
import Utils from './components/Utils';
import Home from './pages/Home/Home';
import ScreenLicence from './pages/company/ScreenLicence/ScreenLicence';
import { CompanySchemaWithoutSiret } from './utils/schemas';
import { useMutation } from 'react-query';
import PatchnotesList from './pages/admin/Patchnotes/PatchnotesList';
import PatchnoteForm from './pages/admin/Patchnotes/PatchnoteForm';
import { ShepherdTour } from 'react-shepherd';

Modal.setAppElement('#root');

function App() {
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  // Ajout numfiscal
  const [numFiscal, setNumFiscal] = useState<string>('');
  const [isModalNumFiscalOpen, setIsModalNumFiscalOpen] = useState<boolean>(false);

  let schema = CompanySchemaWithoutSiret;

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
        label: 'fg',
      },
    },
    useModalOverlay: true,
    exitOnEsc: true,
    keyboardNavigation: false,
  };

  const { mutate: updateCompany, isLoading } = useMutation(api.companies.updateCompany, {
    onSuccess: async (data) => {
      setIsModalNumFiscalOpen(false);
      window.location.reload();
    },
  });

  useEffect(() => {
    api.loginApi
      .getMe()
      .then((data) => {
        logger('%cReconexion avec le JWT', 'color: blue');

        if (data.user && data.user.company && !data.user.company.numFiscal && isAdmin(data.user) && !window.location.pathname.match('/appLogin')) {
          setIsModalNumFiscalOpen(true);
        }

        setUser(data.user);
        setHasCheckLogin(true);
        refetchMe();
      })
      .catch((err) => {
        logger(err);
        setHasCheckLogin(true);
        setUser(undefined);
      });

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          logger('%cAcualisation du JWT', 'color: blue');

          localStorage.setItem('jwt', data.jwt);

          setUser(data.user);

          setInterval(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          logger(err);
          setUser(undefined);
        });
    }
  }, [setUser, setHasCheckLogin]);

  return (
    <>
      {/* @ts-ignore */}
      <ShepherdTour steps={[]} tourOptions={tourOptions}>
        <Router>
          <Utils.TopBar />
          <ContactBubble />

          <Routes>
            <Route
              path="/"
              element={
                <SidePannelCompany>
                  <Home />
                </SidePannelCompany>
              }
            />

            <Route path="/login" element={<Login />} />

            <Route path="/appLogin" element={<AppLogin />} />

            <Route path="/resend-email" element={<ResendEmail />} />

            <Route path="/register-confirmation/:token" element={<RegisterConfirm />} />

            <Route path="/register/companies/:signupLink" element={<Register />} />

            <Route path="/register/consultant" element={<Register consultants={true} />} />

            <Route path="/register/:code" element={<Register />} />

            <Route path="/register" element={<Register />} />

            <Route path="/forget-password" element={<ForgetPassword />} />

            <Route path="/reset-password/:token" element={<FormResetPassword />} />

            {/* Applications */}
            <Route
              path="/apps"
              element={
                <>
                  {/* <NeedLogin needCompany> */}
                  <SidePannelCompany>
                    <CustomersApplicationsList />
                  </SidePannelCompany>
                  {/* </NeedLogin> */}
                </>
              }
            />

            {/* Administration */}
            <Route
              path="/a/user/edit/:id"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <CreateUser />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/user/create"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <CreateUser />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/user"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <AllUsers />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/app/create"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <ApplicationForm />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/app/:id"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <ApplicationForm />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/app"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <AdminApplicationsList />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/company/edit/:id"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <CompanyFormAdmin />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/company/create"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <CompanyFormAdmin />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/company/testing"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <Testing />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/company"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <CompanyList />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/consultants/unconfirmed"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <Unconfirmed />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            {/* Patchnotes */}

            <Route
              path="/a/patchnotes"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <PatchnotesList />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/patchnotes/create"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <PatchnoteForm />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            <Route
              path="/a/patchnotes/edit/:id"
              element={
                <NeedLogin role={ROLE.ROLE_ADMIN}>
                  <SidePannelAdmin>
                    <IsBrowserOk>
                      <PatchnoteForm />
                    </IsBrowserOk>
                  </SidePannelAdmin>
                </NeedLogin>
              }
            />

            {/* Entreprises */}

            <Route
              path="/company/users"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <IsBrowserOk>
                      <UserList />
                    </IsBrowserOk>
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            <Route
              path="/company/users/stats"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <IsBrowserOk>
                      <UserStats />
                    </IsBrowserOk>
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            {/**/}

            <Route
              path="/company/create"
              element={
                <NeedLogin>
                  <IsBrowserOk>
                    <CompanyForm update={false} />
                  </IsBrowserOk>
                </NeedLogin>
              }
            />

            <Route
              path="/company/edit"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <IsBrowserOk>
                      <CompanyForm update={true} />
                    </IsBrowserOk>
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            <Route
              path="/company/storage"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <IsBrowserOk>
                      <Storage />
                    </IsBrowserOk>
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            <Route
              path="/company/screen-licence/add"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <IsBrowserOk>
                      <ScreenLicence />
                    </IsBrowserOk>
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            {/* Ne marche pas, corriger vite /!\ */}
            <Route
              path="/company/referal"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <Referal />
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            <Route
              path="/company/trial-request"
              element={
                <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
                  <SidePannelCompany>
                    <TrialRequest />
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            {/* <Route path="/company">
            <NeedLogin needCompany role={ROLE.ROLE_COMPANY_ADMIN}>
              <SidePannelCompany>
                <IsBrowserOk>
                  <MyCompany />
                </IsBrowserOk>
              </SidePannelCompany>
            </NeedLogin>
          </Route> */}

            {/* Utilisateurs */}
            <Route
              path="/me"
              element={
                <NeedLogin>
                  <SidePannelCompany>
                    <MyProfile />
                  </SidePannelCompany>
                </NeedLogin>
              }
            />

            {/* Consultants */}
            <Route
              path="/c/remunerations"
              element={
                <NeedLogin role={ROLE.ROLE_CONSULTANT}>
                  <SidePannelConsultant>
                    <IsBrowserOk>
                      <Remunerations />
                    </IsBrowserOk>
                  </SidePannelConsultant>
                </NeedLogin>
              }
            />

            <Route
              path="/c/commissions"
              element={
                <NeedLogin role={ROLE.ROLE_CONSULTANT}>
                  <SidePannelConsultant>
                    <IsBrowserOk>
                      <Commissions />
                    </IsBrowserOk>
                  </SidePannelConsultant>
                </NeedLogin>
              }
            />

            <Route
              path="/c"
              element={
                <NeedLogin role={ROLE.ROLE_CONSULTANT}>
                  <SidePannelConsultant>
                    <IsBrowserOk>
                      <InformationConsultant />
                    </IsBrowserOk>
                  </SidePannelConsultant>
                </NeedLogin>
              }
            />

            {/* Privacy */}
            <Route path="/privacy" element={<Privacy />} />
            {/* 
          <Route exact path="/">
            <NeedLogin>
              <Index />
            </NeedLogin>
          </Route> */}

            <Route path="*" element={<Navigate to="/apps" />} />
          </Routes>
        </Router>

        <ReactQueryDevtools />

        <Utils.Modal
          isOpen={isModalNumFiscalOpen}
          onClose={() => {
            setIsModalNumFiscalOpen(false);
          }}
          title="Numéro fiscal manquant"
          closable={false}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              Pour pouvoir utiliser l'application, vous devez dorénavant renseigner votre numéro fiscal. Ce numéro est utilisé pour la facturation de
              vos abonnements.
              <Winylo.TextInput
                label="Numéro de TVA intracommunautaire"
                required
                placeholder="exemple : FR32123456789 "
                name="numFiscal"
                value={numFiscal}
                onChange={(e) => setNumFiscal(e.target.value)}
                error={!(schema as any).fields.numFiscal.isValidSync(numFiscal)}
                style={{ marginTop: '1.5rem' }}
              />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                <Winylo.Button
                  format="square"
                  onClick={() => user?.company && updateCompany({ id: user?.company?.id, body: { numFiscal } })}
                  disabled={!(schema as any).fields.numFiscal.isValidSync(numFiscal) || isLoading}
                >
                  Définir un numéro Fiscal
                </Winylo.Button>
              </div>
            </div>
          </div>
        </Utils.Modal>
      </ShepherdTour>
    </>
  );
}

export default App;
