import React from 'react';
import * as Mantine from '@mantine/core';
import * as MantineDates from '@mantine/dates';
import { WINYLO_THEME } from '..';

interface IDatePickerInputProps extends MantineDates.DatePickerInputProps {
  datePickerInputRef?: React.Ref<HTMLButtonElement>;
  color?: Mantine.DefaultMantineColor;
  format?: 'round' | 'square';
  customColor?: Record<'grape', Mantine.Tuple<string, 10>>;
}

export default function DatePickerInput({ datePickerInputRef, color = 'blue', format = 'square', customColor, ...props }: IDatePickerInputProps) {
  return (
    <Mantine.MantineProvider
      theme={{
        ...WINYLO_THEME,
        colors: {
          ...WINYLO_THEME.colors,
          ...customColor,
        },
        primaryColor: color,
      }}
    >
      <MantineDates.DatePickerInput ref={datePickerInputRef} radius={format === 'round' ? '2.5rem' : '0.25rem'} {...props} />
    </Mantine.MantineProvider>
  );
}
