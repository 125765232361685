import * as Yup from 'yup';

export interface ApplicationInterface {
  name: string;
  description: string | null;
  denomination: string;
  urlApi: string;
  urlWeb: string;
  urlIos: string;
  urlAndroid: string;
  availableRoles: string;
  price: string;
  accessToken: string | null;
  trialDuration: string | null;
  clientId: string;
  // clientSecret: string;
  redirectUris: string;
  image: undefined | FileList;
  imageUrl: null | string;
  isBeta: boolean | null;
  comingSoon: boolean | null;
  hasStorage: boolean | null;
}

export const ApplicationSchema = Yup.object().shape({
  name: Yup.string().required().min(2).max(255),
  description: Yup.string(),
  denomination: Yup.string().required().min(2).max(255),
  urlApi: Yup.string().url(),
  urlWeb: Yup.string().url(),
  urlIos: Yup.string().url(),
  urlAndroid: Yup.string().url(),
  availableRoles: Yup.mixed()
    .test({
      name: 'isStringArray',
      message: 'not an array of string',
      test: (value: any) => {
        try {
          let arr = JSON.parse(value);

          let isOk = true;

          arr.forEach((a: any) => {
            if (typeof a !== 'string') {
              isOk = false;
            }
          });

          return isOk;
        } catch (err) {
          return false;
        }
      },
    })
    .required(),
  accessToken: Yup.string(),
  trialDuration: Yup.string(),
  redirectUris: Yup.mixed()
    .test({
      name: 'isStringArray',
      message: 'not an array of string',
      test: (value: any) => {
        try {
          let arr = JSON.parse(value);

          let isOk = true;

          arr.forEach((a: any) => {
            if (typeof a !== 'string') {
              isOk = false;
            }
          });

          return isOk;
        } catch (err) {
          return false;
        }
      },
    })
    .required('Requis'),
  isBeta: Yup.boolean(),
  comingSoon: Yup.boolean(),
  hasStorage: Yup.boolean(),
});

export interface CompanyInterface {
  name: string;
  siret: string;
  numFiscal: string;
  invoicingEmail: string;
  phone: string;
}

export const CompanySchema = Yup.object().shape({
  name: Yup.string().min(2).max(255).required(),
  siret: Yup.mixed().test({
    name: 'siretValidation',
    message: 'Siren invalide',
    test: (value: string) => {
      let siretCode: string | undefined = undefined;

      if (value.match(/^[0-9]{9}$/) !== null) {
        siretCode = (value.match(/^[0-9]{9}$/) as string[])[0];
      }

      if (siretCode === undefined) {
        return false;
      }

      siretCode = siretCode.replace(/ /g, '');

      let siretSplited = siretCode.split('').map((s, index) => {
        let dizaine = Math.trunc((parseInt(s) * ((index % 2) + 1)) / 10);
        let unite = (parseInt(s) * ((index % 2) + 1)) % 10;

        return dizaine + unite;
      });

      let reducer = (accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      };
      let siretSplitedSum = siretSplited.reduce(reducer);

      return siretSplitedSum % 10 === 0;
    },
  }),
  numFiscal: Yup.mixed().test({
    name: 'numFiscalValidation',
    message: 'Numéro de TVA intracommunautaire invalide',
    test: (value: string) => {
      let numFiscal: string | undefined = undefined;

      const regex =
        /^(ATU|BE0|BG[0-9]{9}|HR[0-9]{11}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL[0-9]{9}|ES[A-Z0-9][0-9]{7}[A-Z0-9]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}|[0-9]{12}|GD[0-4A-Z][0-9]{2})|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z0-9][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10})$/;

      const matchResult = value.match(regex);

      if (matchResult !== null) {
        numFiscal = (matchResult as string[])[0];
      }

      return !!numFiscal;
    },
  }),
  invoicingEmail: Yup.string().email().min(2),
  phone: Yup.mixed().test({
    name: 'phoneValidation',
    message: 'Téléphone invalide',
    test: (value) => {
      if (value === null) return false;

      return value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/) !== null;
    },
  }),
});

export const CompanySchemaWithoutSiret = Yup.object().shape({
  name: Yup.string().min(2).max(255).required(),
  numFiscal: Yup.mixed().test({
    name: 'numFiscalValidation',
    message: 'Numéro de TVA intracommunautaire invalide',
    test: (value?: string) => {
      let numFiscal: string | undefined = undefined;

      const regex =
        /^(ATU[0-9]{9}|BE0[0-9]{9}|BG[0-9]{9}|HR[0-9]{11}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL[0-9]{9}|ES[A-Z0-9][0-9]{7}[A-Z0-9]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}|[0-9]{12}|GD[0-4A-Z][0-9]{2})|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z0-9][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10})$/;

      const matchResult = value?.match(regex);

      if (!!matchResult) {
        numFiscal = (matchResult as string[])[0];
      }

      return !!numFiscal;
    },
  }),
  invoicingEmail: Yup.string().email().min(2),
  phone: Yup.mixed().test({
    name: 'phoneValidation',
    message: 'Téléphone invalide',
    test: (value) => {
      if (value === null) return false;

      return value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/) !== null;
    },
  }),
});

export const CompanyUpdateSchema = Yup.object().shape({
  name: Yup.string().min(2).max(255).required(),
  // code: Yup.string().min(2).max(20).required(),
});

export const UserSchema = Yup.object().shape({
  username: Yup.string().min(2).max(255).required(),
  email: Yup.string().email().required(),
  plainPassword: Yup.mixed().test((value) => value === '' || value === undefined || value.length >= 6),
  firstname: Yup.string().min(2).max(255).required(),
  lastname: Yup.string().min(2).max(255).required(),
});

export const InvoiceAddressSchema = Yup.object().shape({
  city: Yup.string().min(2).max(255).required(),
  country: Yup.mixed().test((value) => value !== ''),
  line1: Yup.string().min(2).max(255).required(),
  postalCode: Yup.number() /*.mixed().test((value) => value.match(/[0-9]{5}/) !== null)*/,
});

export const PatchnoteSchema = Yup.object().shape({
  title: Yup.string().min(2).max(255).required(),
  content: Yup.string().min(2).max(2048).required(),
  type: Yup.string().min(2).max(255).required(),
  application: Yup.number().required(),
  scheduledAt: Yup.date().required(),
});
