import { ReactElement } from 'react';
import { string } from 'yup';
import { FILTER_OPTION } from '../../components/Utils/Sort/Sort';
import { PRODUCT_TYPE } from '../constantes';
import { vatVerifiedEnum } from '../enums/vatVerifiedEnum';

export enum ROLE {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN',
  ROLE_COMPANY_ACCOUNTANT = 'ROLE_COMPANY_ACCOUNTANT',
  ROLE_USER = 'ROLE_USER',
  ROLE_CONSULTANT = 'ROLE_CONSULTANT',
  ROLE_INVOICING = 'ROLE_INVOICING',
}

export interface CompanyUser {
  id: number;
  username: string;
  email: string | null;
  company: SmallCompany | null;
  firstname: string;
  lastname: string;
  roles: ROLE[];
  applicationRoles: ApplicationRole[];
  canConnect: boolean;
  waitingValidation: boolean | null;
}

export interface UserSmall {
  id: number;
  username: string;
  email: string;
  waitingValidation: boolean | null;
}

export interface User extends UserSmall {
  createdAt: string;
  lastConnectionAt: string;
  canConnect: boolean;
  roles: ROLE[];
  company: SmallCompany | null;
  firstname: string;
  lastname: string;
  referralCode?: string;
  applicationRoles: ApplicationRole[];
}

export interface ApplicationRole {
  application: { id: number };
  roles: string[];
}

export interface SmallCompany {
  id: number;
  name: string;
  uri: string | null;
  numFiscal: string | null;
  vatVerified: vatVerifiedEnum;
}

export interface CompanySignupLink extends SmallCompany {
  uri: string | null;
  signupLink: string | null;
}

export interface MidCompany extends SmallCompany {
  companyProducts: CompanyProduct[];
  dénomination: string;
  siret: string | null;
  phone: string;
}

export interface TestingCompany extends MidCompany {
  users?: {
    id: number;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
  }[];
}

export interface Company extends MidCompany {
  users: User[];
  createdAt: string;
  code: string;
  referralCode: string | null;
  invoicingEmail: string;
  hasValidPaymentMethod: boolean;
  defaultInvoiceAddress: null | { id: number };
  signupLink: string | null;
}

export interface CompanyProduct {
  active: boolean;
  id: number;
  product: Application | CompanyStorage;
  trialStartedAt: string | null;
  trialEndedAt: string | null;
  trialDone: boolean | null;
  type: 'application' | 'storage';
  status: PRODUCT_STATUS;
  internalReference?: string;
  subscriptionEnd: string | null;
}

export enum PRODUCT_STATUS {
  TRIAL = 'trial',
  TRIAL_REQUEST = 'trial_request',
  TRIAL_REFUSED = 'trial_refused',
  TRIAL_EXPIRED = 'trial_expired',
  UNSUBSCRIBED = 'unsubscribed',
  SUBSCRIBED = 'subscribed',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface CompanyProductApplication extends CompanyProduct {
  product: Application;
  type: 'application';
}

export interface CompanyProductStorage extends CompanyProduct {
  product: CompanyStorage;
  type: 'storage';
}

export interface CompanyStorage {
  id: number;
  name: string;
  price: number;
}

export interface SmallApplication extends Product {
  fileUri: string | null;
}

export interface MidApplication extends SmallApplication {
  urlApi: string;
  urlWeb: string;
  urlIos: null | string;
  urlAndroid: null | string;
  trialDuration: number | null;
  denomination: string;
  description: string | null;
  comingSoon: boolean | null;
  isBeta: boolean | null;
  hasStorage: boolean | null;
}

export interface Application extends MidApplication {
  denomination: string;
  availableRoles: ROLE[];
  accessToken: string | null;
  clientId: string;
  // clientSecret: string;
  redirectUris: string[];
}

export interface SubmitModalParams {
  title: string;
  content: ReactElement;
  checked: boolean;
  isOpen: boolean;
  errorCode?: number;
}

// Body de request
export interface CreateApplication {
  name: string;
  denomination: string;
  urlApi: string;
  urlWeb: string;
  urlAndroid: string | null;
  urlIos: string | null;
  availableRoles: ROLE[];
  accessToken: string | null;
  trialDuration: number | null;
  redirectUris: string[];
  file: undefined | File;
}

export interface CreateUserInterface {
  id: number;
  username: string;
  email: string;
  plainPassword: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  isUsernameUpdated: boolean;
}

export interface ParsedCreateUserInterface {
  id?: number;
  username?: string;
  email: string;
  plainPassword?: string;
  firstname: string;
  lastname: string;
  isUsernameUpdated?: boolean;
}

export interface CsvAssociation {
  firstname: number;
  lastname: number;
  email: number;
  password?: number;
  username?: number;
  hasHeader?: boolean;
}

// Transactions
export interface Invoice {
  amount_due: number;
  created: number;
  id: string;
  invoice_pdf: string;
  number: string;
  status: string;
  customer?: string; // pour les Invoice Admin
}

export interface Pagination {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;
}

export interface PageItem<T> {
  pagination: Pagination;
  items: T[];
}

export interface ApplicationStats {
  applicationName: string;
  stats: StatsItem[] | [];
}

export interface StatsItem {
  name: string;
  value: number | string;
}

export interface Subscription {
  current_period_end: number;
  current_period_start: number;
  start_date: number;
  created: number;
  status: string;
  products: SubscriptionProduct[];
  unsubscribed: Product[];
}

// Remplacer le type application dans subscription
export interface Product {
  id: number;
  name: string;
  price: number | null;
  type: string;
}

export interface SubscriptionProduct {
  price: SubscriptionPrice;
  product: Product;
  quantity?: number;
  internalReference?: string;
}

export interface Addon extends Product {}

export interface SubscriptionPrice {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: 'eur';
  id: string;
  livemode: boolean;
  lookup_key: null;
  metadata: [];
  nickname: string | null;
  object: string;
  product: string;
  recurring: {
    aggregate_usage: null;
    interval: string;
    interval_count: number;
    trial_period_days: null | number;
    usage_type: string;
  };
  tax_behavior: string;
  tiers_mode: null;
  transform_quantity: null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface PaymentMethod {
  type: 'card' | 'sepa_debit';
  id: string;
  last4: string;
  brand?: string;
  exp_month?: number;
  exp_year?: number;
  default: boolean;
}

export interface InvoiceAddress {
  id: number;
  city: string;
  country: string;
  line1: string;
  postalCode: string;
}

export interface StorageExtension {
  id: number;
  name: string;
  price: number;
  description: null;
  type: 'storage';
}

export interface ScreenLicence {
  id: number;
  name: string;
  price: number;
  description: null;
  type: 'screen_licence';
}

export type TRIAL_REQUEST_STATUS = 'granted' | 'pending' | 'refused';

export interface TrialRequest {
  id: number;
  comment: string;
  createdBy: UserSmall;
  createdAt: string;
  application: SmallApplication;
  status: TRIAL_REQUEST_STATUS;
  respondedBy: UserSmall;
  response: string | null;
}

export interface ReferedUser {
  id: number;
  company?: {
    totalAmount: number | null;
    dateFirstInvoice: string | null;
  } & Company;
  firstname: string;
  lastname: string;
}

/**
 * User-management-v2
 */
export interface UserFilter {
  [key: string]: string | null;
  global: string;
  firstname: FILTER_OPTION;
  lastname: FILTER_OPTION;
  username: FILTER_OPTION;
  email: FILTER_OPTION;
  role: FILTER_OPTION;
}

export interface UserFormValue {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  password: string;
  password_confirm: string;
  application_roles: ApplicationRole[];
}

export interface ShareFormValue {
  addresses: string[];
  subject: string;
  message: string;
}

export interface EmailOption {
  readonly label: string;
}

export interface CSVOption {
  label: string;
  value: string;
  required?: boolean;
}

export interface CreateCompanyUserBody {
  firstname: string;
  lastname: string;
  email?: string;
  username?: string;
  plainPassword?: string;
}

export interface ManualInvoice {
  id: number;
  company: SmallCompany;
  createdAt: string;
  amount: number;
  url: string;
  numInvoice: string;
}

export interface Patchnote {
  id: number;
  title: string;
  content: string;
  type: string;
  application: SmallApplication;
  scheduledAt: string;
}

export interface Tag {
  label: string;
  color: string;
}
